import {
  assignSessionBylevel,
  deleteSingleMediumAvailability,
  deleteSubSessionData,
  getMettingAvailability,
  getSessionData,
  getSessionMediumshipRecords,
  getSessionType,
  getSingleMediumAvailability,
  getSubSessionDetails,
  saveSession,
  saveSubSession,
  setAdvancedMediumAvailability,
  setSpecificMediumAvailability,
  syncGoogleCalendar,
} from "../../../services/admin";
import {
  setassignSessionArray,
  setCalendarSession,
  setScheduleArray,
  setSessionFormInfo,
  setSessionTypes,
} from "../../../reducer/adminSlice";
import { getCountry, getLanguages } from "../../../services/uidata";
import {
  setaLanguagesArray,
  setCountryArray,
} from "../../../reducer/uidataSlice";
import {
  convertToLocal,
  convertToUtc,
  removeString,
  formatTime,
} from "../../../utils/helpers";
import { getTimeImage } from "../../../utils/utils";
import { toast } from "react-toastify";
import { setLoading, setLoadingContent, setLoadingText } from "../../../reducer/auth/auth";

const getSessions = (dispatch) => {
  getSessionData()
    .then((data) => {
      dispatch(setCalendarSession(data.data.data));
    })
    .catch(() => { });
};
const updateSessionName = (body, calendarSessions, dispatch) => {
  let sessions = calendarSessions.map((data) => {
    if (data.session_id === body.session_id) {
      return {
        ...data,
        session_name: body.session_name,
      };
    } else {
      return {
        ...data,
      };
    }
  });
  saveSession(body)
    .then((data) => {
      if (data.data.data) {
        dispatch(setCalendarSession(sessions));

        getSessions(dispatch);
        getSessionTypes(dispatch);
        if (body.session_id === "" || body.session_id === undefined) {
        }
        window.loadModal2("#practiceModal", "hide");
      }
    })
    .catch(() => { });
};
const addMainSessions = (body, formType, dispatch, setRedirectType) => {
  saveSubSession(body)
    .then(() => {
      toast.success(
        formType !== "Edit"
          ? "Session Created Succesfully"
          : "Session Updated Succesfully",
        { position: "top-center" }
      );
      getSessions(dispatch);
      setRedirectType('Sessions-list');
    })
    .catch(() => { });
};
const getSessionTypes = (dispatch) => {
  getSessionType()
    .then((data) => {

      dispatch(setSessionTypes(data.data.data));
    })
    .catch(() => { });
};

const getSubsessionInfo = (id, dispatch) => {
  getSubSessionDetails(id)
    .then((data) => {
      dispatch(setSessionFormInfo(data.data.data));
    })
    .catch(() => { });
};
const setFormValues = (info, initialValues, setValues) => {
  initialValues.sub_session_name = info.sub_session_name;
  initialValues.session_type = info.session_type;
  // max_number_of_participant= "",
  initialValues.duration = info.duration;
  initialValues.day = info.get_subsession_days.map((item) => {
    return {
      value: item.day,
      label: item.day,
    };
  });
  initialValues.min_booking_hour = info.min_booking_hour;
  initialValues.min_booking_time = info.min_booking_time;
  initialValues.max_booking_hour = info.max_booking_hour;
  initialValues.max_booking_time = info.max_booking_time;
  initialValues.questions =
    info.getsubsessionquestions.length > 0
      ? mapValues(info.getsubsessionquestions)
      : [{ question: "", answer: "" }];
  initialValues.pricing_type = info.pricing_type;
  initialValues.fixedprice = info.minprice;
  initialValues.minprice = info.minprice;
  initialValues.maxprice = info.maxprice;
  initialValues.session_type_radio = info.get_session_type;
  initialValues.group_limit = info.group_limit;
  initialValues.participant_level =
    (info.participant_level !== null && info.participant_level !== "") ? 1 : "";
  initialValues.student_level =
    (info.student_level !== null && info.student_level !== "") ? 1 : "";

  setValues(initialValues);
};
const clearInitialValues = (initialValues) => {
  initialValues.sub_session_name = "";
  initialValues.session_type = "";
  initialValues.max_number_of_participant = "";
  initialValues.duration = "";
  initialValues.day = "";
  initialValues.min_booking_hour = "";
  initialValues.min_booking_time = "";
  initialValues.max_booking_hour = "";
  initialValues.max_booking_time = "";
  initialValues.questions = [{ question: "", answer: "" }];
  initialValues.pricing_type = "free";
  initialValues.fixedprice = "";
  initialValues.minprice = "";
  initialValues.maxprice = "";
  initialValues.session_type_radio = {};
  initialValues.group_limit = "";
  initialValues.max_attendees = "";
};
const assignSessions = (body, dispatch) => {
  assignSessionBylevel(body)
    .then((data) => {
      if (data.data.status) {
        updateAssignSessionArray(dispatch);
        toast.success("Assigned Session level", { position: "top-center" });
      }
    })
    .catch(() => { });
};
const setMediumAvailability = (body) => {
  setSpecificMediumAvailability(body)
    .then((data) => {
      if (data.data.status) {
        toast.success("Specific dates added", { position: "top-center" });
      }
      // window.loadModal2("#demo-modal", "hide");
    })
    .catch(() => { });
};
const setAdvancedAvailability = (
  body,
  setmediumAvailabilityId,
  dispatch,
  openSchedules
) => {
  console.log(body, "body")
  // return;
  dispatch(setLoadingContent(`<div>Updating your availability throughout our system and your personal calendar. </div>`));
  dispatch(setLoadingText(true));
  setAdvancedMediumAvailability(body)
    .then((data) => {
      dispatch(setLoadingText(false));
      dispatch(setLoadingContent(""))
      if (data.data.status) {
        let check = localStorage.getItem('showpreview')
        setmediumAvailabilityId(data.data.data.id);
        toast.success(check !== "showpreview" ? "Updated" : 'Wait one minute and then check your Preview.', { position: "top-center" });
        openSchedules();
      } else {
        toast.error(data.data.message, { position: "top-center" });
      }
    })
    .catch(() => {
      dispatch(setLoadingText(false));
      dispatch(setLoadingContent(""))
    });
};
const getSchedules = (ID, dispatch) => {
  getMettingAvailability(ID)
    .then((data) => {
      dispatch(setScheduleArray(data.data.data));
    })
    .catch(() => { });
};
const updateLanguage = (dispatch) => {
  getLanguages()
    .then((data) => {
      dispatch(setaLanguagesArray(data.data.data));
      // setFormLanguages(data.data.data);
    })
    .catch((err) => console.log(err));
};
const updateCountries = (dispatch) => {
  getCountry()
    .then((data) => {
      dispatch(setCountryArray(data.data.data));
    })
    .catch((err) => console.log(err));
};
const updateAssignSessionArray = (dispatch) => {
  getSessionMediumshipRecords()
    .then((data) => {
      if (data.data.status) {

        dispatch(setassignSessionArray(data.data.data));
      }
    })
    .catch(() => { });
};
const updateSingleMediumAvailability = (id, updateForm) => {
  getSingleMediumAvailability(id)
    .then((data) => {
      if (data.data.status) {
        updateForm(data.data.data);
      }
    })
    .catch(() => { });
};

const getCountryTypesedit = (type, countries, items) => {
  if (countries.length) {
    let country;
    if (type === "All Countries with Exclusions") {
      const res = items.filter(
        (entry1) =>
          !countries.some(
            (entry2) => parseInt(entry1.value) === parseInt(entry2)
          )
      );
      country = res.map((item) => {
        return {
          ...item,
          value: item.value,
          label: item.label,
        };
      });
    }

    if (type === "Selected Countries only") {
      const res = items.filter((entry1) =>
        countries.some((entry2) => parseInt(entry1.value) === parseInt(entry2))
      );

      country = res.map((item) => {
        return {
          ...item,
          value: item.value,
          label: item.label,
        };
      });
    }
    // stepTwoData.exclusions =
    //   type == "All Countries with Exclusions" ? country : "";
    // stepTwoData.inclusions = type == "Selected Countries only" ? country : "";
    return country;
  }
};

const getCountryTypesAdd = (type, countries, items) => {
  if (countries.length) {
    let country;
    if (type === "All Countries with Exclusions") {
      const res = items.filter(
        (entry1) =>
          !countries.some(
            (entry2) => parseInt(entry1.value) === parseInt(entry2.id)
          )
      );
      country = res.map((item) => {
        return {
          ...item,
          value: item.value,
          label: item.label,
        };
      });
    }

    if (type === "Selected Countries only") {
      const res = items.filter((entry1) =>
        countries.some(
          (entry2) => parseInt(entry1.value) === parseInt(entry2.id)
        )
      );

      country = res.map((item) => {
        return {
          ...item,
          value: item.value,
          label: item.label,
        };
      });
    }
    // stepTwoData.exclusions =
    //   type == "All Countries with Exclusions" ? country : "";
    // stepTwoData.inclusions = type == "Selected Countries only" ? country : "";
    return country;
  }
};

const addScheduleForm = (initialValues, profilePreferences, newCountryData) => {
  initialValues.meeting_preferences_meeting = profilePreferences.languages.map(
    (item, index) => {
      return { value: item.language_id, label: item.lanugage_name };
    }
  );
  if (
    profilePreferences !== null &&
    profilePreferences.country_type === "All Countries with Exclusions"
  ) {
    initialValues.exclusions = getCountryTypesAdd(
      profilePreferences.country_type,
      profilePreferences.countries,
      newCountryData
    );
  }
  if (
    profilePreferences !== null &&
    profilePreferences.country_type === "Selected Countries only"
  ) {
    initialValues.inclusions = getCountryTypesAdd(
      profilePreferences.country_type,
      profilePreferences.countries,
      newCountryData
    );
  }

  initialValues.countries =
    profilePreferences.countries.length !== 0
      ? profilePreferences.countries.map((item, index) => {
        return { value: item.id, label: item.country_name };
      })
      : [];

  initialValues.country_type =
    profilePreferences.country_type !== ""
      ? profilePreferences.country_type
      : "All Countries";
  initialValues.recording = parseInt(profilePreferences.allow_recordings);
  initialValues.specificDateSlots =
    initialValues.specificDateSlots.length === 0
      ? [{ date: "", time: [{ from: "", to: "", time_slot_id: "" }] }]
      : initialValues.specificDateSlots;

  initialValues.blockedDateSlots =
    initialValues.blockedDateSlots.length === 0
      ? [{ date: "", time: [{ from: "", to: "", time_slot_id: "" }] }]
      : initialValues.blockedDateSlots;
};
const checkUnitType = (value, setUnitValue) => {
  if (value.min_booking_days !== "00" && value.min_booking_days !== "") {
    setUnitValue('Days')
  }
  if (value.min_booking_time !== "00" && value.min_booking_time !== "") {
    setUnitValue('Minutes')
  }
  if (value.min_booking_hour !== "00" && value.min_booking_hour !== "") {
    setUnitValue('Hours')
  }
}
const updateScheduleForm = (
  info,
  initialValues,
  sessions,
  type,
  setValues,
  languages,
  newCountryData,
  settestValues,
  setBlockedDatesValues,
  setUnitValue,
  level,
  setShowGroupSession,
  setShowSpecificAddDates,
  setshowGroupNotification,
  setshowpreffered,
  setshowNoOfSesionPerDay,
  setdisablePreferredType,
  sessionTypes
) => {
  const main_info = info.main_info;
  const {
    friday,
    monday,
    saturday,
    sunday,
    thursday,
    tuesday,
    wednesday,
  } = JSON.parse(main_info.slots);
  // console.log('countryType',countryType)
  initialValues.receive_email = main_info.receive_email === 1 ? true : false;
  initialValues.cancellation_email = main_info.cancellation_email === 1 ? true : false;
  initialValues.max_attendees = main_info.max_attendees;
  console.log(parseInt(main_info.sub_session_type), parseInt(process.env.REACT_APP_COACHING_SESSION_ID), "main_info main_info main_info main_info main_info")
  initialValues.start_date = main_info.start_date;
  initialValues.end_date = main_info.end_date;
  initialValues.buffer_time = main_info.buffer_time;
  initialValues.number_of_session_per_day = main_info.number_of_session_per_day;
  initialValues.is_group_session = main_info.is_group_session
  //preffered medium level
 
    let value = main_info.preferred_medium_level !== null ? JSON.parse(main_info.preferred_medium_level) : ["1", "2", "3", "4", "5", "6", "7"]

    // let pre_med = value.includes(level.toString()) ? value : value.push(level.toString())

    initialValues.preferred_medium_level = value

  initialValues.min_booking_days = `${Math.floor(main_info.min_book_time / 24) >= 10 ? "" : 0
    }${Math.floor(main_info.min_book_time / 24)}`;

  initialValues.min_booking_hour =
    Math.floor(main_info.min_book_time) % 24 !== 0
      ? `${Math.floor(main_info.min_book_time) % 24 >= 10 ? "" : 0}${Math.floor(
        main_info.min_book_time
      ) % 24}`
      : "";

  initialValues.min_booking_time = main_info.min_book_time.includes(".")
    ? main_info.min_book_time
      .toString()
      .split(".")
      .pop()
    : "";

  initialValues.max_booking_days =
    main_info.max_book_time !== null || main_info.max_book_time !== ""
      ? parseInt(main_info.max_book_time) / 24 < 10
        ? `0${parseInt(main_info.max_book_time) / 24}`
        : `${parseInt(main_info.max_book_time) / 24}`
      : null;

  initialValues.meeting_preferences_meeting = languages.filter(
    (item, index) => {
      if (main_info.language.includes(item.value)) {
        return { value: item.value, label: item.label };
      }
    }
  );
  settestValues(
    main_info.specificDateSlots.length === 0
      ? []
      : Object.entries(info.main_info.specificDateSlots).map(([key, value]) => {
        return {
          date: key,
          time: value.map((item) => {
            return {
              from:
                item.from !== ""
                  ? {
                    label: checkTime(formatTime(removeString(item.from))),
                    value: checkTime(formatTime(removeString(item.from))),
                    change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
                  }
                  : "",
              to:
                item.to !== ""
                  ? {
                    label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                    value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                    change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
                  }
                  : "",
            };
          }),
        };
      })
  );
  setBlockedDatesValues(
    main_info.blockedDateSlots.length === 0
      ? []
      : Object.entries(info.main_info.blockedDateSlots).map(([key, value]) => {
        return {
          date: key,
          time: value.map((item) => {
            return {
              from:
                item.from !== ""
                  ? {
                    label: checkTime(formatTime(removeString(item.from))),
                    value: checkTime(formatTime(removeString(item.from))),
                    change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
                  }
                  : "",
              to:
                item.to !== ""
                  ? {
                    label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                    value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                    change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
                  }
                  : "",
            };
          }),
        };
      })
  );
  initialValues.specificDateSlots = [
    { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
  ];
  initialValues.blockedDateSlots = [
    { date: "", time: [{ from: "", to: "", time_slot_id: "" }] },
  ];


  initialValues.recording = main_info.recording;
  if (main_info.country_type === "All Countries with Exclusions") {
    initialValues.exclusions = getCountryTypesedit(
      main_info.country_type,
      main_info.country,
      newCountryData
    );
  }
  if (main_info.country_type === "Selected Countries only") {
    initialValues.inclusions = getCountryTypesedit(
      main_info.country_type,
      main_info.country,
      newCountryData
    );
  }
  initialValues.country_type = main_info.country_type;
  // initialValues.countries = newCountryData.filter((item, index) => {
  //   if (main_info.country.includes(item.value)) {
  //     return { value: item.value, label: item.label };
  //   }
  // });

  // initialValues.recording_participants = JSON.parse(
  //   main_info.participant_preferences
  // ).recording_participants;
  let ad = sessions.filter((item) => {
    if (parseInt(item.sub_session_id) === parseInt(main_info.sub_session_type)) {
      return { item };
    }
  })
  initialValues.session_type = type === "admin" ? ad[0].sub_session_id : sessions.filter((item) => {
    if (parseInt(item.sub_session_id) === parseInt(main_info.sub_session_type)) {
      return { item };
    }
  });
let  sessionList = sessionTypes.filter((item)=> item.sub_session_id == main_info.sub_session_type);
console.log(sessionList,"sessionList" ,sessionTypes);
    if (sessionList[0].is_group_session == 1) {
      setshowGroupNotification(true)
      setShowGroupSession(true);
      setshowpreffered(true);
      setShowSpecificAddDates(true)
      setshowNoOfSesionPerDay(false)
    }
    else {

    }
    if (sessionList[0].display_mediumship_level_checkboxes == 1) {
      setshowpreffered(true);
    }
    else {
      setshowpreffered(false);
    }

    if (sessionList[0].enable_mediumship_level_selection ==0) {
      setdisablePreferredType(true)
    }
    else {
      setdisablePreferredType(false)
    }




  /////////////////////
  initialValues.student_level = JSON.parse(main_info.student_level);
  initialValues.partner_level = JSON.parse(main_info.partner_level);
  initialValues.medium_availability_id = main_info.id;
  initialValues.payment_type = main_info.payment_type;

  initialValues.payment_id = main_info.payment_id;
  initialValues.scheduleSunday =
    sunday !== undefined
      ? sunday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
                // image: getTimeImage(checkTime(formatTime(removeString(item.from))))
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'

                // image: getTimeImage(checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM':formatTime(removeString(item.to)),'to'))
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.scheduleMonday =
    monday !== undefined
      ? monday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.scheduleTuesday =
    tuesday !== undefined
      ? tuesday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.scheduleWednesday =
    wednesday !== undefined
      ? wednesday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.scheduleThursday =
    thursday !== undefined
      ? thursday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.scheduleFriday =
    friday !== undefined
      ? friday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.scheduleSaturday =
    saturday !== undefined
      ? saturday.map((item) => {
        return {
          from:
            item.from !== ""
              ? {
                label: checkTime(formatTime(removeString(item.from))),
                value: checkTime(formatTime(removeString(item.from))),
                change: formatTime(removeString(item.from)) == '11:59 PM' ? 'NO' : 'YES'
              }
              : "",
          to:
            item.to !== ""
              ? {
                label: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                value: checkTime(formatTime(removeString(item.to)) === '12:00 AM' ? '11:59 PM' : formatTime(removeString(item.to)), 'to'),
                change: formatTime(removeString(item.to)) === '12:00 AM' ? 'NO' : 'YES'
              }
              : "",
        };
      })
      : [{ from: "", to: "" }];
  initialValues.daysunday =
    sunday !== undefined && sunday.length > 0 ? true : false;
  initialValues.daymonday =
    monday !== undefined && monday.length > 0 ? true : false;
  initialValues.daytuesday =
    tuesday !== undefined && tuesday.length > 0 ? true : false;
  initialValues.daywednesday =
    wednesday !== undefined && wednesday.length > 0 ? true : false;
  initialValues.daythursday =
    thursday !== undefined && thursday.length > 0 ? true : false;
  initialValues.dayfriday =
    friday !== undefined && friday.length > 0 ? true : false;
  initialValues.daysaturday =
    saturday !== undefined && saturday.length > 0 ? true : false;

  setValues(initialValues);

  checkUnitType(initialValues, setUnitValue);
};
const checkTime = (time, dir) => {
  if (time === '11:59 PM') return '12:00 AM'
  return time
}
const clearScheduleForm = (initialValues) => {
  initialValues.medium_id = "";
  initialValues.valid_from = "";
  initialValues.valid_to = "";
  initialValues.session_type = "";
  initialValues.buffer_time = "15";
  initialValues.number_of_session_per_day = 1;
  initialValues.min_booking_hour = "";
  initialValues.min_booking_time = "";
  initialValues.min_booking_days = '01';
  initialValues.max_booking_hour = "";
  initialValues.max_booking_time = "";
  initialValues.meeting_preferences = "";
  initialValues.participant_preferences = "";
  initialValues.meeting_preferences_meeting = [];
  initialValues.meeting_preferences_participants = [];
  initialValues.specificDateSlots = [];
  initialValues.blockedDateSlots = [];

  initialValues.recording_meeting = "";
  initialValues.recording_participants = "";
  initialValues.group_limit = "";
  initialValues.partner_level = [];
  initialValues.student_level = [];
  initialValues.payment_type = 1;
  initialValues.payment_id = "";
  initialValues.start_date = "";
  initialValues.end_date = "";
  initialValues.scheduleSunday = [{ from: "", to: "" }];
  initialValues.scheduleMonday = [{ from: "", to: "" }];
  initialValues.scheduleTuesday = [{ from: "", to: "" }];
  initialValues.scheduleWednesday = [{ from: "", to: "" }];
  initialValues.scheduleThursday = [{ from: "", to: "" }];
  initialValues.scheduleFriday = [{ from: "", to: "" }];
  initialValues.scheduleSaturday = [{ from: "", to: "" }];
  initialValues.countries = [];
  initialValues.exclusions = [];
  initialValues.inclusions = [];
  initialValues.country_type = "";
  initialValues.daysunday = false;
  initialValues.daymonday = false;
  initialValues.daytuesday = false;
  initialValues.daywednesday = false;
  initialValues.daythursday = false;
  initialValues.dayfriday = false;
  initialValues.daysaturday = false;
  initialValues.receive_email = false;
  initialValues.cancellation_email = false;
  initialValues.max_attendees = "";
  // initialValues.preferred_medium_level = [];

};
const deleteSchedule = (id, dispatch, ID) => {
  dispatch(setLoadingContent('<div>We are your processing your request</div>'))
  dispatch(setLoadingText(true))
  deleteSingleMediumAvailability(id)
    .then((data) => {

      if (data.data.status) {
        dispatch(setLoadingContent('<div>We are your processing your request</div>'))
        dispatch(setLoadingText(false))
        getSchedules(ID, dispatch);
      }
      else {
        dispatch(setLoadingContent(''))
        dispatch(setLoadingText(false))
      }
    })
    .catch(() => {
      dispatch(setLoadingContent(''))
      dispatch(setLoadingText(false))
    });
};

const deletesubsessions = (id, dispatch) => {
  let body = {
    session_id: id,
  };
  deleteSubSessionData(body)
    .then((data) => {
      if (data.data.status) {
        getSessions(dispatch);
        toast.success("Session Deleted", { position: "top-center" });
      }
    })
    .catch(() => { });
};
const syncCalendar = (navigate) => {
  syncGoogleCalendar()
    .then((data) => {
      if (data.data.status) {
        toast.success("Calendar Sycned Succesfully", {
          position: "top-center",
        });
      }
      if (
        !data.data.status &&
        data.data.message ===
        "Please authorize to google calendar it is either expired or not added"
      ) {
        toast.error("Please Link your personal calendar", {
          position: "top-center",
        });
        setTimeout(() => {
          navigate("/integration");
        }, 2000);
      }
    })
    .catch(() => { });
};
//helpers
const mapValues = (array) => {
  return array.map((info) => {
    return {
      question: info.add_booking_questions,
      answer: info.answer,
    };
  });
};
const makeHours = (item) => {
  let hours = [];
  for (var input = 1; input <= item; input++) {
    hours.push(input >= 10 ? input : `0${input}`);
  }
  return hours;
};
export {
  getSessions,
  addMainSessions,
  getSessionTypes,
  makeHours,
  updateSessionName,
  getSubsessionInfo,
  setFormValues,
  clearInitialValues,
  assignSessions,
  setMediumAvailability,
  updateLanguage,
  updateCountries,
  getSchedules,
  updateSingleMediumAvailability,
  updateScheduleForm,
  addScheduleForm,
  deleteSchedule,
  setAdvancedAvailability,
  clearScheduleForm,
  deletesubsessions,
  updateAssignSessionArray,
  syncCalendar,
};
